import { useEffect, useState } from "react";
import { useRemember } from "@inertiajs/inertia-react";

export default (initialValue, rules = [], effect = () => {}, rememberable = false) => {

  const _state = rememberable ? useRemember : useState;

  const [value, setValue] = _state(initialValue);
  const [error, setError] = _state(false);

  const checkErrorValidation = () => {
    const validArr = rules.map(rule => rule(value));
    const check = validArr.find(v => typeof v === "string") || false;
    return check;
  };

  const isValid = () => {
    setError(false);
    const checkError = checkErrorValidation();
    if(checkError){
      setError(checkError);
    }

    return checkError === false;
  };

  const onChange = e => {

    let v;

    if(e && e.target){
      v = e.target.value;
    } else {
      v = e;
    }

    setValue(v);
  };

  useEffect(() => {
    effect(value, setValue);
  }, [value]);

  return {
    model: {
      value,
      onChange,
      error: error != false,
      helperText: error || "",
    },
    value,
    error: error != false,
    setError,
    isValid,
    setValue
  };
};