import i18n from "i18next";
import { Fragment, useEffect, useState } from "react";
import Sidebar from "@/components/Sidebar/Index";
import Navbar from "@/components/Navbar/Index";
import Chat from "@/components/Chat/Index";
import { Box } from "@mui/material";
import { usePage } from "@inertiajs/inertia-react";
import { createConsumer } from "@rails/actioncable";
import { Head } from "@inertiajs/inertia-react";

const Main = ({
  chatStates,
  chatClose,
  sidebarOpen,
  page
}) => {
  const { PAGE_TITLE, TITLE } = usePage().props;

  const [messages, setMessages] = useState([]);
  const { IS_PRODUCTION, BASE_DOMAIN, CURRENT_USER, LANG } = usePage().props;

  const cableControl = (data) => {
    const customEvent = new CustomEvent(`${data.cable_type}_${data.cable_event}_CABLE`, { detail: data });
    document.dispatchEvent(customEvent);
  };

  const path = window.location.pathname;

  useEffect(() => {
    const cable = createConsumer(`ws${IS_PRODUCTION ? "s" : ""}://${BASE_DOMAIN}/cable`);

    const subscription = cable.subscriptions.create({
      channel: "CablesChannel",
      user_id: CURRENT_USER.id,
      // socket_id: uuidv4()
    }, {
      connected () { },
      disconnect () {
        cable.subscriptions.remove(subscription);
        cable.disconnect();
      },
      received (data) {
        cableControl(data);
      }
    });
    return () => {
      cable.subscriptions.remove(subscription);
      cable.disconnect();
    };
  }, []);

  useEffect(() => {
    i18n.changeLanguage(LANG || "en");
  }, []);


  return (
    <div className="flex flex-row w-full !p-0 h-full transition-all duration-200 ease-linear">
      <Head>
        <title>{`${TITLE ? `${TITLE} -` : ""} ${PAGE_TITLE ? PAGE_TITLE : ""}`}</title>
      </Head>
      <Sidebar
        chatOpen={chatStates.open}
        chatToggle={chatClose}
      />
      <div
        className={`w-full bg-transparent transition-all ease-linear duration-200 ${sidebarOpen ? "lg:pl-[256px]" : "pl-[0px"}`}
      >
        <Chat
          sidebarOpen={sidebarOpen}
          chatStates={chatStates}
          messages={messages}
          setMessages={setMessages}
        />
        {!sidebarOpen && window.innerWidth <= 1024 && (
          <Fragment>
            <Navbar />
            <Box component="main" className={`${path !== "/messenger" && "!px-4 lg:!px-8 py-6"}`}>
              {page}
            </Box>
          </Fragment>
        )}
        { window.innerWidth >= 1025 && (
          <Fragment>
            <Navbar />
            <Box component="main" className="!px-4 lg:!px-8 py-6">
              {page}
            </Box>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Main;