import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { MdCake, MdGroupAdd, MdLogout, MdMarkEmailUnread, MdMenu, MdNotifications, MdOpenInNew, MdOutlineGroup, MdOutlineNotifications, MdOutlineSms, MdOutlineStorefront, MdOutlineWatchLater, MdPages, MdPerson, MdPostAdd, MdWorkHistory, MdWorkOff } from "react-icons/md";
import SidebarContext from "../Sidebar/Context";
import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Link, usePage } from "@inertiajs/inertia-react";
import { Fetch } from "@/helpers/fetch";
import { api_authorization_logout_path, api_employee_times_path, api_last_notifications_path, api_offices_list_select_path, api_offices_update_office_select_path, api_update_all_notification_path, ecommerces_path, home_path, messenger_index_path, notification_setting_profile_path, notifications_path, profiles_path } from "@/compiled/routes";
import { closeSnackbar, enqueueSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import useInput from "@/hooks/useInput";
import { Avatar, Backdrop, Button, IconButton, Menu, MenuItem, TextField } from "@mui/material";
import { Inertia } from "@inertiajs/inertia";

const Navbar = () => {
  const { CURRENT_USER, BASE_URL } = usePage().props;
  const { t } = useTranslation();
  const {
    open, handle
  } = useContext(SidebarContext);

  const [notification, setNotification] = useState([]);
  const [notificationNew, setNotificationNew] = useState(0);

  // const [subscription, setSubscription] = useState(false);
  const [userImage, setUserImage] = useState({});

  const [userOffices, setUserOffices] = useState([]);

  const officeInput = useInput(CURRENT_USER.office_select || "");
  const [work, setWork] = useState(CURRENT_USER.check_time);

  const [anchorEl, setAnchorEl] = useState(null);
  const anchorRef = useRef(null);

  const [anchorElNotificate, setAnchorElNotificate] = useState(null);
  const anchorRefNotificate = useRef(null);

  const { handleClick, handleClose } = {
    handleClick: (event) => setAnchorEl(event.currentTarget),
    handleClose: () => setAnchorEl(null),
  };

  const [confirm, setConfirm] = useState(false);

  const sendSms = (status, text) => {
    if (status == "incoming_sms") {
      Inertia.visit(messenger_index_path({ phone_number: text.match(/<b>(.*?)<\/b>/)[1] }));
    } else if (status == "ecommerce") {
      Inertia.visit(ecommerces_path());
    }
  };

  const onConfirm = () => {
    setConfirm(true);

    let isLoading = false;

    const handle = (snackbarId) => {
      if (isLoading) {
        return false;
      }

      isLoading = true;

      Fetch.post(api_employee_times_path(), {
        check: !work,
      })
        .then(({ data }) => {
          setWork(!work);
          setConfirm(false);
          enqueueSnackbar(data.message, { variant: "success" });
          setTimeout(() => {
            window.location.reload();
            closeSnackbar(snackbarId);
          }, 800);
        })
        .catch(({ response }) => {
          setConfirm(false);
          isLoading = false;
          enqueueSnackbar(response.data.message || t("unprocessable_request"), { variant: "error" });
          setTimeout(() => {
            closeSnackbar(snackbarId);
          }, 800);
        });
    };

    const cancel = (snackbarId) => {
      closeSnackbar(snackbarId);
      setConfirm(false);
    };

    enqueueSnackbar(
      <div className="flex flex-row items-center space-x-4">
        <div>{!work ? t("are_you_sure_to_check_in") : t("are_you_sure_to_check_out")}</div>
        <div className="flex items-center flex-none space-x-2">
          <Button
            size="small"
            variant="contained"
            className={`${!work ? "!bg-blue-500" : "!bg-orange-500"}`}
            onClick={() => handle()}
          >
            {t("yes_send")}
          </Button>
          <Button
            onClick={() => cancel()}
            size="small"
            color="inherit"
          >{t("cancel")}</Button>
        </div>
      </div>, {
      variant: !work ? "info" : "warning",
      persist: true,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
      },
    }
    );
  };

  const handleClickNotification = (event) => {
    setAnchorElNotificate(event.currentTarget);
    setNotificationNew(0);
    Fetch.put(api_update_all_notification_path())
      .then(({ data }) => {
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(t("fetch_table_error"), { variant: "error" });
      });
  };

  const handleCloseNotification = () => {
    setAnchorElNotificate(null);
  };

  const logout = () => {
    Fetch.delete(api_authorization_logout_path())
      .then(({ data }) => {
        window.location.href = data.redirect;
      })
      .catch((res) => {
        console.log(res);
      });
  };

  useEffect(() => {
    Fetch.get(api_offices_list_select_path())
      .then(({ data }) => {
        setUserOffices(data);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(t("unprocessable_request"), { variant: "error" });
      });
  }, []);

  useEffect(() => {
    if (officeInput.value) {
      if (CURRENT_USER.office_select !== officeInput.value) {
        Fetch.put(api_offices_update_office_select_path({
          value: officeInput.value
        }))
          .then(({ data }) => {
            enqueueSnackbar(data.message, { variant: "success" });
            setTimeout(() => {
              window.location.reload();
            }, 800);
          })
          .catch(({ response }) => {
            officeInput.setValue(CURRENT_USER.office_select);
            enqueueSnackbar(response.data.message || t("unprocessable_request"), { variant: "error" });
          });
      }
    }
  }, [officeInput.value]);

  useEffect(() => {
    Fetch.get(api_last_notifications_path())
      .then(({ data }) => {
        setNotification(data.notifications);
        setNotificationNew(data.new);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(t("unprocessable_request"), { variant: "error" });
      });
  }, []);

  useEffect(() => {
    const handle = ({ detail: cable_data }) => {
      const { data } = cable_data;

      const temp = [data.notification, ...notification];

      if (temp.length > 4) {
        temp.pop();
      }

      setNotification(temp);
      enqueueSnackbar(
        temp[0].notification_type == "incoming_sms"
          ?
          <Link
            href={messenger_index_path({ phone_number: temp[0].title.match(/<b>(.*?)<\/b>/)[1] })}
            role="button"
            onClick={() => closeSnackbar()}
            className="flex flex-col"
          >
            <span>{t("new_notification")}</span>
            <span dangerouslySetInnerHTML={{ __html: temp[0].title }}></span>
          </Link>
          :
        temp[0].notification_type == "ecommerce"
          ?
          <Link
            href={ecommerces_path()}
            role="button"
            onClick={() => closeSnackbar()}
            className="flex flex-col"
          >
            <span>{t("new_notification")}</span>
            <span dangerouslySetInnerHTML={{ __html: temp[0].title }}></span>
          </Link>
          :
          <div
            role="button"
            onClick={() => closeSnackbar()}
            className="flex flex-col"
          >
            <span>{t("new_notification")}</span>
            <span dangerouslySetInnerHTML={{ __html: temp[0].title }}></span>
          </div>, {
        variant: "info",
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        // style: {
          // marginTop: "50px",
        // },
      },
      );

      const tempNew = notificationNew + data.new;
      setNotificationNew(tempNew);
      if (CURRENT_USER.notification_sound) {
        const audio = new Audio(`${BASE_URL}/sound/notification.wav`);
        audio.play();
      }
    };

    document.addEventListener("USER_NOTIFICATION_LOAD_CABLE", handle);
    return () => document.removeEventListener("USER_NOTIFICATION_LOAD_CABLE", handle);
  }, [notification]);


  useEffect(() => {
    const handle = ({ detail: cable_data }) => {
      const { data } = cable_data;
      if (data.working.id == CURRENT_USER.id) {
        setWork(data.working.work);
      }
    };

    document.addEventListener("EMPLOYEE_TIME_LOAD_CABLE", handle);
    return () => document.removeEventListener("EMPLOYEE_TIME_LOAD_CABLE", handle);
  }, []);

  useEffect(() => {
    if (CURRENT_USER.profile_image) {
      setUserImage({
        src: CURRENT_USER.profile_image,
        alt: CURRENT_USER.display_name
      });
    } else {
      setUserImage({
        children: `${CURRENT_USER.first_name[0]}${CURRENT_USER.last_name[0]}`
      });
    }
  }, []);

  return (
    <div
      id="navbar"
      className="w-full"
    >
      <AppBar
        position="relative"
        className="w-full border-b border-[#f3f3f3]"
        color="inherit"
        variant="string"
      >
        <Toolbar
          style={{
            backgroundColor: work ? "#4caf5038" : ""
          }}
        >
          <div
            className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-row items-center space-x-4">
              <IconButton
                id="button-handle-sidebar"
                color="info"
                onClick={() => handle(!open)}
              >
                <span className={`transition ease-in-out duration-200 ${open ? "rotate-90" : "rotate-0"}`}>
                  <MdMenu className="flex-none w-6 h-6 text-gray-600" />
                </span>
              </IconButton>
              <div className="flex flex-row items-center space-x-4 ">
                <MdOutlineWatchLater className={`sm:hidden  w-5 h-5  ${work && window.innerWidth < 400 ? "fill-green-500" : "fill-red-500"}`} />
                <MdOutlineWatchLater className="hidden w-5 h-5 md:block" />
                <span className="hidden md:block">{t("status")}</span>
                <div className={`text-center rounded-full py-1 text-white hidden md:block font-medium px-4 text-xs ${work ? "bg-green-500" : "bg-red-500"}`}>
                  {work ? t("working") : t("off_text") }
                </div>
              </div>
              <button
                type="button"
                className={`!w-fit px-2 py-1 rounded ${!work ? "!bg-blue-500" : "!bg-orange-500"}`}
                onClick={() => onConfirm()}
              >
                {work ?
                  <div className="flex flex-row items-center space-x-2">
                    <MdWorkOff className="w-4 h-4 fill-white" />
                    <span className="py-1 text-xs font-medium text-center text-white rounded-full">
                      {t("check_out")}
                    </span>
                  </div>
                  :
                  <div className="flex flex-row items-center space-x-2">
                    <MdWorkHistory className="w-4 h-4 fill-white" />
                    <span className="py-1 text-xs font-medium text-center text-white rounded-full">
                      {t("check_in")}
                    </span>
                  </div>
                }
              </button>
            </div>
            <div className="flex flex-row items-center space-x-4">
              <div className="hidden md:block">
                <Link href={home_path()} >
                  <Button
                    variant="contained"
                    className="!bg-green-500"
                    sx={{ borderRadius: "20px" }}
                  >
                    <div className="flex flex-row items-center space-x-2 capitalize">
                      <MdMarkEmailUnread className="w-5 h-5 text-white" />
                      <div>{t("corp_communications")}</div>
                    </div>
                  </Button>
                </Link>
              </div>

              <TextField
                select
                size="small"
                className='!w-44 !hidden lg:!flex '
                variant="standard"
                InputProps={{
                  style: { backgroundColor: work ? "#4caf5000" : "white" }
                }}
                {...officeInput.model}
              >
                {userOffices.map((option) => (
                  <MenuItem key={option.value} value={option.value} className="!capitalize">
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <div>
                <div
                  role="button"
                  className="relative"
                  onClick={handleClickNotification}
                  ref={anchorRefNotificate}
                >
                  <MdOutlineNotifications className="text-gray-700 w-7 h-7" />
                  {notificationNew != 0 && (
                    <div className="absolute flex items-center justify-center w-5 h-5 font-semibold text-white rounded-full -top-2 -right-2 bg-primary-500">
                      <span className="text-[11px] text-center">
                        {notificationNew}
                      </span>
                    </div>
                  )}
                </div>
                <Menu
                  anchorEl={anchorRefNotificate.current}
                  open={Boolean(anchorElNotificate)}
                  onClose={handleCloseNotification}
                  className="mt-6"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                // sx={{ width: 300 }}
                >
                  <div className="w-full max-w-[470px]">
                    <span className="px-4 font-semibold">{t("notifications")}</span>
                    <div className="mt-2 border-b"></div>
                    {notification.length > 0 && (
                      <Fragment>
                        <div className="divide-y">
                          {notification.map((x, i) => (
                            <div
                              key={i}
                              onClick={() => sendSms(x.notification_type, x.title)}
                              role="button"
                              className={`flex items-center text-sm justify-between px-4 py-2 space-x-4 ${!x.read && "bg-primary-100"}`}
                            >
                              <div role="button" className="flex items-center py-1 space-x-4">
                                {x.notification_type == "leads" && (
                                  <MdOutlineGroup className="flex-none p-1 rounded-full h-7 w-7 bg-primary-100" />
                                )}
                                {x.notification_type == "ecommerce" && (
                                  <MdOutlineStorefront className="flex-none p-1 rounded-full h-7 w-7 bg-teal-100" />
                                )}
                                {x.notification_type == "patient_form" && (
                                  <MdPostAdd className="flex-none p-1 bg-gray-100 rounded-full fill-gray-400 h-7 w-7" />
                                )}
                                {x.notification_type == "google_review" && (
                                  <MdPages className="flex-none p-1 bg-yellow-100 rounded-full fill-yellow-300 h-7 w-7" />
                                )}
                                {x.notification_type == "incoming_sms" && (
                                  <MdOutlineSms className="flex-none p-1 bg-red-100 rounded-full fill-red-300 h-7 w-7" />
                                )}
                                {x.notification_type == "birthdate" && (
                                  <MdCake className="flex-none p-1 bg-green-100 rounded-full fill-green-300 h-7 w-7" />
                                )}
                                {x.notification_type == "group_sms" && (
                                  <MdGroupAdd className="flex-none p-1 rounded-full bg-primary-100 fill-primary-300 h-7 w-7" />
                                )}
                                <span dangerouslySetInnerHTML={{ __html: x.title }}></span>
                                {["incoming_sms", "ecommerce"].includes(x.notification_type) && (
                                  <MdOpenInNew className="w-4 h-4 fill-primary-500"/>
                                )}
                              </div>
                              <div className="text-[9px] w-12 items-center text-center text-gray-500">{x.create}</div>
                            </div>
                          ))}
                        </div>
                        <div className="flex justify-center">
                          <Button href={notifications_path()} className="!capitalize">
                            {t("view_notifications")}
                          </Button>
                        </div>
                      </Fragment>
                    )}
                    {notification.length == 0 && (
                      <div className="p-4 text-center text-gray-400">{t("no_pending_notifications")}...</div>
                    )}
                  </div>
                </Menu>
              </div>
              <div className="flex flex-row items-center space-x-4" ref={anchorRef}>
                <div className="flex items-center space-x-2" role="button" onClick={handleClick}>
                  <Avatar
                    {...userImage}
                    sx={{ width: 36, height: 36, background: "#3B82F6" }}
                  ></Avatar>
                  <span className="hidden text-sm capitalize lg:inline">
                    {CURRENT_USER.display_name}
                  </span>
                </div>
                <Menu
                  anchorEl={anchorRef.current}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  className="mt-5"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "right" }}
                >
                  <div className="w-full my-1">
                    <div className="flex flex-row items-center px-4 pb-1 space-x-2">
                      <Avatar
                        {...userImage}
                        sx={{ width: 36, height: 36, background: "#3B82F6" }}
                      ></Avatar>
                      <div className="flex flex-col">
                        <span className="text-sm font-semibold capitalize">{CURRENT_USER.display_name}</span>
                        <span className="text-sm text-gray-400 capitalize">{CURRENT_USER.role}</span>
                      </div>
                    </div>
                    <div className="my-1 border-b"></div>
                    <div className="p-2 lg:hidden">
                      <TextField
                        select
                        size="small"
                        className='!w-full'
                        variant="outlined"
                        {...officeInput.model}
                      >
                        {userOffices.map((option) => (
                          <MenuItem key={option.value} value={option.value} className="!capitalize">
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                    <div className="my-1 border-b lg:hidden"></div>
                    <div className="mt-2 text-gray-500">
                      <Link href={profiles_path()} onClick={handleClose} className="flex items-center px-4 py-2 space-x-4 hover:bg-gray-100">
                        <MdPerson />
                        <span>{t("view_profile")}</span>
                      </Link>
                      <div role="button" className="flex items-center py-2 space-x-4 hover:bg-gray-100">
                        <div className="flex-none">
                          <Link href={notification_setting_profile_path()} onClick={handleClose} className="flex items-center px-4 py-2 space-x-4 hover:bg-gray-100">
                            <MdNotifications />
                            <span>{t("notification_settings")}</span>
                          </Link>
                        </div>
                      </div>
                      <div role="button" className="flex items-center px-4 py-2 space-x-4 hover:bg-gray-100"
                        onClick={logout}
                      >
                        <MdLogout />
                        <span>{t("log_out")}</span>
                      </div>
                    </div>
                  </div>
                </Menu>
              </div>
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Backdrop
        className="z-30"
        open={confirm}
      ></Backdrop>
    </div>
  );
};
export default Navbar;