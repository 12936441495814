import { TbTilde } from "react-icons/tb";

const ItemSms = ({
  isGroup,
  message,
  CURRENT_USER
}) => {
  return (
    <div
      className="px-2 py-1"
    >
      {(!isGroup || (isGroup && message && message.message_type == "normal")) && (
        <div
          className={`flex ${message.sender_id && message.sender_id == CURRENT_USER.id ? "justify-end" : "justify-start"}`}
        >
          <div
            className={`p-2 w-full max-w-[60%] ${isGroup ? "!bg-white" : "" } !rounded-b-md ${message.sender_id && message.sender_id == CURRENT_USER.id ? `!rounded-tl-md !rounded-tr-none ${!isGroup && "bg-primary-500 !text-white"}` : `!rounded-tl-none !rounded-tr-md ${!isGroup && "bg-green-400 text-white" }`}`}
            style={{ wordWrap: "break-word" }}
          >
            {isGroup && (
              <div
                style={{ color: message.color }}
                className="flex flex-row text-xs font-medium"
              >
                <TbTilde className="w-4 h-4 mr-1"/>
                <span>{message.display_name}</span>
              </div>
            )}
            <div className="">{message.body}</div>
            <div className="mt-1 text-xs ">{message.created}</div>
          </div>
        </div>
      ) }
      {isGroup && message && message.message_type != "normal" && (
        <div className="flex items-center justify-center w-full">
          <div
            className="px-3 text-[10px] text-center text-white bg-gray-700 rounded-xl max-w-[220px] py-0.5 font-light"
          >{message.body}</div>
        </div>
      ) }
   </div>
  );
};

export default ItemSms;