import { Badge } from "@mui/material";
import styled from "@emotion/styled";

const BadgeOnline = styled(Badge)(({ active, theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: active ? "#44b700" : "#ccc",
    color: active ? "#44b700" : "#ccc",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: active ? "ripple 1.2s infinite ease-in-out" : "",
      border: "1px solid currentColor",
      content: "\"\"",
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export default BadgeOnline;