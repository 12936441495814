import { api_chat_create_group_path, api_chat_delete_group_path, api_chat_update_group_path, api_chat_users_path } from "@/compiled/routes";
import { Fetch } from "@/helpers/fetch";
import { Button, Checkbox, Chip, FormControlLabel, IconButton, List, Menu, Paper, Slide, TextField } from "@mui/material";
import { Fragment, useState, useRef, useEffect } from "react";
import { CgSpinner } from "react-icons/cg";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import useInput from "@/hooks/useInput";
import { useTranslation } from "react-i18next";
import BadgeOnline from "../Badge/Online";
import BoxContent from "../Chat/BoxContent";
import { usePage } from "@inertiajs/inertia-react";
import { MdArrowBack, MdClose, MdDelete, MdOutlineGroup, MdOutlineMail, MdOutlineMoreVert } from "react-icons/md";

const Chat = ({
  chatStates,
  sidebarOpen,
}) => {
  const { CURRENT_USER } = usePage().props;

  const { t } = useTranslation();

  const { open } = chatStates;

  const [chatIsOpen, setChatIsOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState("users");

  const [groupSetting, setGroupSetting] = useState(false);
  const [groupSelected, setGroupSelected] = useState({ id: null });
  const [groups, setGroups] = useState([]);
  const [groupsFiltered, setGroupFiltered] = useState([]);

  const [isFetched, setIsFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const queryInput = useInput("");

  const [userSelected, setUserSelected] = useState({ id: null });
  const [users, setUsers] = useState([]);
  const [userFiltered, setUserFiltered] = useState([]);

  const [messages, setMessages] = useState([]);
  const [totalUnreadMessages, setTotalUnreadMessages] = useState(0);
  const [usersUnreadMessages, setUsersUnreadMessages] = useState({});


  const [totalUnreadMessagesGroup, setTotalUnreadMessagesGroup] = useState(0);
  const [usersUnreadMessagesGroup, setUsersUnreadMessagesGroup] = useState({});

  const [isIOs, setIsIOs] = useState(false);

  const slideRef = useRef(null);

  const [newGroup, setNewGroup] = useState(false);
  const [userGroup, setUserGroup] = useState([]);
  const [userGroupEdit, setUserGroupEdit] = useState([]);

  const [diffusion, setDiffusion] = useState(false);
  const [usersDiffusion, setUsersDiffusion] = useState([]);

  const anchorRefMenuChat = useRef(null);
  const [anchorElMenuChat, setAnchorElMenuChat] = useState(null);

  const [sendDiffusion, setSendDiffusion] = useState(false);

  const handleDiffusion = () => {
    setDiffusion(true);
    setCurrentTab("users");
    handleCloseMenuChat();
    setUserSelected({ id: null });
    setGroupSetting(false);
  };

  const handleNewGroup = () => {
    setNewGroup(true);
    setCurrentTab("group");
    handleCloseMenuChat();
    setUserSelected({ id: null });
    setGroupSetting(false);
  };

  const handleUserGroup = (user) => {
    const temp = userGroup.find(x => x.id === user.id);
    if (temp) {
      const temp = [...userGroup].filter((e) => e.id !== user.id);
      setUserGroup(temp);
    } else {
      setUserGroup([...userGroup, user]);
    }
  };

  const handleUserGroupEdit = (user) => {
    const temp = userGroupEdit.find(x => x === user);
    if (temp) {
      const temp = [...userGroupEdit].filter((e) => e !== user);
      setUserGroupEdit(temp);
    } else {
      setUserGroupEdit([...userGroupEdit, user]);
    }
  };

  const handleUserDiffusion = (user) => {
    const temp = usersDiffusion.find(x => x.id === user.id);
    if (temp) {
      const temp = [...usersDiffusion].filter((e) => e.id !== user.id);
      setUsersDiffusion(temp);
    } else {
      setUsersDiffusion([...usersDiffusion, user]);
    }
  };

  const { handleClickMenuChat, handleCloseMenuChat } = {
    handleClickMenuChat: (event) => setAnchorElMenuChat(event.currentTarget),
    handleCloseMenuChat: () => setAnchorElMenuChat(null),
  };

  const handleArrowBackDiffusion = () => {
    setDiffusion(false);
    setCurrentTab("users");
    setUsersDiffusion([]);
    setGroupSetting(false);
  };

  const setTabToUser = () => {
    setDiffusion(false);
    setCurrentTab("users");
    setUsersDiffusion([]);
    setGroupSetting(false);
    setNewGroup(false);
  };

  const setTabToGroup = () => {
    setDiffusion(false);
    setCurrentTab("group");
    setUsersDiffusion([]);
    setGroupSetting(false);
    setNewGroup(false);
  };

  const fetch = () => {
    Fetch.get(api_chat_users_path())
      .then(({ data }) => {
        setIsLoading(false);
        setUsers(data.users);
        setGroups(data.groups);
      });
  };

  const unreadMessagesBadge = (item_id, isGroup = false) => {
    const total = isGroup ? usersUnreadMessagesGroup[item_id] : usersUnreadMessages[item_id];

    if (total > 0) {
      return (
        <div>
          <span className="text-sm font-bold text-primary-500">({total})</span>
        </div>
      );
    } else {
      return "";
    }
  };

  const deleteGroup = () => {
    Fetch.put(api_chat_delete_group_path(), {
      group_id: groupSelected.id
    })
      .then(({ data }) => {
        const temp = [...groups].filter((x) => x.id != groupSelected.id);
        setGroups(temp);
        setNewGroup(false);
        setCurrentTab("group");
        setUserSelected({ id: null });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createGroup = () => {
    if(userGroup.length <= 0){
      return false;
    }

    Fetch.post(api_chat_create_group_path(), {
      users: userGroup.map(x => x.id).join(",")
    })
      .then(({ data }) => {
        setNewGroup(false);
        setCurrentTab("group");
        setUserSelected({ id: null });
        setUserGroupEdit([]);
        setUserGroup([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateGroup = () => {
    Fetch.put(api_chat_update_group_path(), {
      users: userGroupEdit.join(","),
      group_id: groupSelected.id
    })
      .then(({ data }) => {
        const temp = [...groups].map((x) => {
          if(x.id === data.group.id){
            return data.group;
          } else return x;
        });

        setGroupSelected(data.group);
        setGroups(temp);
        setNewGroup(false);
        setGroupSetting(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setUserHandle = (user) => {
    setGroupSelected(false);
    setUserSelected(user);
    setGroupSetting(false);
  };

  const setGroupHandle = (group) => {
    setUserSelected(false);
    setGroupSelected(group);
    setGroupSetting(false);
    setUsersDiffusion([]);
    setGroupSetting(false);
    setNewGroup(false);
  };

  const usersTab = () => (
    <div className="relative">
      {usersDiffusion.length > 0 && (
        <div className="sticky top-0 z-10 px-4 mb-3 text-center">
          <Button
            variant="contained"
            fullWidth
            onClick={() => setSendDiffusion(true)}
            >
            {t("create_diffusion")}
          </Button>
        </div>
      )}
      <List>
        {userFiltered.map((user, index) => (
          <ListItem className={userSelected && userSelected.id == user.id ? "bg-gray-100" : ""} key={index} component="div" disablePadding>
            {!diffusion && (
              <ListItemButton onClick={() => setUserHandle(user)} className="!px-2">
                <ListItemText
                  className="!truncate !p-0"
                  primary={(
                    <div className="ml-2 leading-[18px]">
                      <div className="flex items-center space-x-3">
                        <div>
                          <BadgeOnline
                            overlap="circular"
                            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                            variant="dot"
                            active={user.online ? "true" : undefined}
                          />
                        </div>
                        <div className="truncate">
                          {user.display_name}
                        </div>
                        {unreadMessagesBadge(user.id)}
                      </div>
                      <span className="ml-3 text-[10px]">{user.role} - {user.ext_number}</span>
                      {user.last_activity_formatted && (
                        <div className="flex items-center space-x-3">
                          <div></div>
                          <div className="text-xs truncate text-gray-10">
                            {user.last_activity_formatted}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                />
              </ListItemButton>
            )}
            {diffusion && (
              <div className="px-4">
                <FormControlLabel
                  control={
                    <Checkbox
                      value={false}
                      onChange={() => handleUserDiffusion(user)}
                    />
                  }
                  label={user.display_name}
                />
              </div>
            )}
          </ListItem>
        ))}
      </List>
    </div>
  );

  const groupTab = () => (
    <div className="relative">
      {newGroup && (
        <div className="px-4 text-xs text-gray-500">{t("select_users_to_group")}</div>
      )}
      <List>
        {newGroup && userGroup.length > 0 && !groupSetting && (
          <div className="sticky top-0 z-10 px-4 mb-3 text-center">
            <Button
              variant="contained"
              fullWidth
              onClick={ createGroup }
            >
              {t("create_group")}
            </Button>
          </div>
        )}

        {!newGroup && groupSetting && (
          <div className="sticky top-0 z-10 flex px-4 mb-3 space-x-4 text-center iitems-center">
            <Button
              variant="contained"
              fullWidth
              onClick={ updateGroup }
              className="!w-full"
            >
              {t("save")}
            </Button>
            {groupSelected && groupSelected.id && groupSelected.creator_id == CURRENT_USER.id && (
              <Button
                variant="contained"
                fullWidth
                onClick={ deleteGroup }
                className="!w-auto"
                color="error"
              >
                <MdDelete className="w-5 h-5" />
              </Button>
            ) }
          </div>
        )}

        { groupSetting && !newGroup && (
          <Fragment>
            {userFiltered.map((user, index) => (
              <div key={ index } className="px-4">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={userGroupEdit.filter(x => x == user.id).length > 0}
                      onChange={() => handleUserGroupEdit(user.id)}
                    />
                  }
                  label={user.display_name}
                />
              </div>
            )) }
          </Fragment>
        ) }

        { !groupSetting && newGroup && (
          <Fragment>
            {userFiltered.map((user, index) => (
              <div key={ index } className="px-4">
                <FormControlLabel
                  control={
                    <Checkbox
                      value={false}
                      onChange={() => handleUserGroup(user)}
                    />
                  }
                  label={user.display_name}
                />
              </div>
            )) }
          </Fragment>
        ) }

        { !groupSetting &&  !newGroup && (
          <Fragment>
            {groupsFiltered.map((group, index) => (
              <ListItem className={groupSelected && groupSelected.id == group.id ? "bg-gray-100" : ""} key={index} component="div" disablePadding>
                {!newGroup && (
                  <ListItemButton onClick={() => setGroupHandle(group)} className="!px-2">
                    <ListItemText
                      className="!truncate !p-0"
                      primary={(
                        <div className="ml-2 leading-[18px]">
                          <div className="flex items-center space-x-3">
                            <div className="truncate">
                              <span>{group.name}</span>
                            </div>
                            {group.recently_created && (
                              <Chip
                                label={ t("new") }
                                size="small"
                                variant="outlined"
                                color="primary"
                                className="!ml-2 !h-[20px]"
                                clickable
                              />
                            )}
                            {unreadMessagesBadge(group.id, true)}
                          </div>
                          <div className="mt-1 text-xs">
                            {group.last_message}
                          </div>
                        </div>
                      )}
                    />
                  </ListItemButton>
                )}
              </ListItem>
            ))}
          </Fragment>
        ) }
      </List>
    </div>
  );

  function ChatList () {
    if (users.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center w-64 h-full italic text-gray-400 border-r">
          {t("no_users")}
        </div>
      );
    }
    return (
      <div className={`flex flex-col w-screen h-full border-r lg:w-80 ${isIOs && "pt-[110px]"}`}>
        <div className="p-3">
          <div className="flex items-center justify-between mb-3 text-sm font-medium text-gray-600 uppercase">
            <div className="flex items-center">
              <span>{t("chat")}</span>
            </div>
            <div className="flex flex-row items-center">
              {!diffusion && (
                <IconButton
                  variant="contained"
                  color="primary"
                  className="lg:!hidden"
                  onClick={() => chatStates.handle(false)}
                >
                  <MdClose />
                </IconButton>
              )}
              {diffusion && (
                <IconButton
                  variant="contained"
                  onClick={handleArrowBackDiffusion}
                >
                  <MdArrowBack className="w-5 h-5" />
                </IconButton>
              )}
              <div ref={anchorRefMenuChat}>
                <IconButton
                  variant="contained"
                  onClick={handleClickMenuChat}
                >
                  <MdOutlineMoreVert className="w-5 h-5" />
                </IconButton>
              </div>
            </div>
            <Menu
              anchorEl={anchorRefMenuChat.current}
              open={Boolean(anchorElMenuChat)}
              onClose={handleCloseMenuChat}
              className="mt-5"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <div className="flex flex-col text-sm m">
                <div
                  role="button"
                  onClick={handleDiffusion}
                  className="flex items-center py-2 space-x-4 hover:bg-gray-100"
                >
                  <span className="inline-flex items-center px-2 space-x-2">
                    <MdOutlineMail className="w-5 h-5 text-gray-500" />
                    <span>{t("new_diffusion")}</span>
                  </span>
                </div>
                <div
                  role="button"
                  onClick={handleNewGroup}
                  className="flex items-center py-2 space-x-4 hover:bg-gray-100"
                >
                  <span className="inline-flex items-center px-2 space-x-2">
                    <MdOutlineGroup className="w-5 h-5 text-gray-500" />
                    <span>{t("create_group")}</span>
                  </span>
                </div>
              </div>
            </Menu>
          </div>
          <TextField
            size="small"
            placeholder={t("search")}
            InputProps={{
              className: "!bg-transparent"
            }}
            fullWidth
            variant="outlined"
            {...queryInput.model}
          />
          <div className="flex items-center mt-1 text-sm font-medium text-center text-gray-500 uppercase">
            <div
              role="button"
              onClick={ setTabToUser }
              className={ `w-1/2 p-3 border-b ${currentTab == "users" ? "border-primary-500" : ""  }` }
            >
              {t("messages")}
              {totalUnreadMessages > 0 && (
                <span className="ml-1">({totalUnreadMessages})</span>
              )}
            </div>
            <div
              role="button"
              onClick={ setTabToGroup }
              className={ `w-1/2 p-3 border-b ${currentTab == "group" ? "border-primary-500" : ""  }` }
            >
              {t("group")}
              {totalUnreadMessagesGroup > 0 && (
                <span className="ml-1">({totalUnreadMessagesGroup})</span>
              )}
            </div>
          </div>
        </div>
        <div
          className="h-full overflow-auto !pt-0"
        >
          { currentTab == "users" && (
            <Fragment>
              { usersTab() }
            </Fragment>
          ) }
          { currentTab == "group" && (
            <Fragment>
              { groupTab() }
            </Fragment>
          ) }
        </div>
      </div>
    );
  }

  useEffect(() => {
    if (queryInput.value.toString().trim() == "") {
      setUserFiltered(users.filter(x => x.id != CURRENT_USER.id));
      setGroupFiltered(groups);
    } else {
      const usersFiltered = [...users].filter(x => x.id != CURRENT_USER.id).filter((user) => {
        const q = queryInput.value.toLowerCase().replace(/\s+/g, "");
        return user.display_name_normalize.replace(/\s+/g, "").includes(q);
      });

      setUserFiltered(usersFiltered);

      const groupsFiltered = [...groups].filter(x => x.id != CURRENT_USER.id).filter((group) => {
        const q = queryInput.value.toLowerCase().replace(/\s+/g, "");
        return group.name.replace(/\s+/g, "").includes(q);
      });

      setGroupFiltered(groupsFiltered);
    }
  }, [users, groups, queryInput.value]);

  useEffect(() => {
    const onTransitionEnd = () => {
      if (!open) {
        setChatIsOpen(false);
      }
    };

    if (open) {
      setChatIsOpen(true);
      if (!isFetched) {
        fetch();
        setIsFetched(true);
      }
    } else {
      if (slideRef && slideRef.current && !open) {
        slideRef.current.addEventListener("transitionend", onTransitionEnd);
      }
    }

    return () => {
      if (slideRef && slideRef.current) {
        slideRef.current.removeEventListener("transitionend", onTransitionEnd);
      }
    };
  }, [open, isFetched]);

  useEffect(() => {
    const handle = ({ detail: cable_data }) => {
      const { data } = cable_data;

      if(data.is_group){
        setUsersUnreadMessagesGroup({
          ...usersUnreadMessagesGroup,
          [data.group_id]: data.total_unread_messages
        });
        setTotalUnreadMessagesGroup(data.total_unread_messages_general);

      } else {
        setUsersUnreadMessages({
          ...usersUnreadMessages,
          [data.user_id]: data.total_unread_messages
        });
        setTotalUnreadMessages(data.total_unread_messages_general);
      }
    };

    document.addEventListener("QUICK_CHAT_MARK_AS_READ_CABLE", handle);
    return () => document.removeEventListener("QUICK_CHAT_MARK_AS_READ_CABLE", handle);
  }, [usersUnreadMessages, usersUnreadMessagesGroup]);

  useEffect(() => {
    const handle = ({ detail: cable_data }) => {
      const { data } = cable_data;
      if(data.is_group){
        const groupExists = data.list.find(x => x.id == groupSelected.id);
        if(!groupExists && data.creator != CURRENT_USER.id && data.is_updated){
          setGroupSelected({ id: null });
        }
        setGroups(data.list);

        if(data.default_selected && data.default_selected > 0){
          setGroupSelected(data.list.find(x => x.id == data.default_selected));
        }

      } else {
        setUsers(data.list);
      }
    };

    document.addEventListener("QUICK_CHAT_LIST_CABLE", handle);
    return () => document.removeEventListener("QUICK_CHAT_LIST_CABLE", handle);
  }, []);

  useEffect(() => {
    const handle = ({ detail: cable_data }) => {
      const { data } = cable_data;
      if(data.is_group){
        if (data.delete) {
          const temp = [...groups].filter((x) => x.id != data.group_id);
          setGroups(temp);
          setGroupSelected({ id: null });
          setGroupSetting(false);
        }
        setUsersUnreadMessagesGroup({
          ...usersUnreadMessagesGroup,
          [data.group_id]: data.total_unread_messages
        });
        setTotalUnreadMessagesGroup(data.total_unread_messages_general);
      } else {
        setUsersUnreadMessages({
          ...usersUnreadMessages,
          [data.user_id]: data.total_unread_messages
        });

        setTotalUnreadMessages(data.total_unread_messages_general);
      }
    };

    document.addEventListener("QUICK_CHAT_RECEIVER_CABLE", handle);
    return () => document.removeEventListener("QUICK_CHAT_RECEIVER_CABLE", handle);
  }, [usersUnreadMessages, usersUnreadMessagesGroup]);

  useEffect(() => {
    if(groupSetting && groupSelected){
      setUserGroupEdit(groupSelected.members || []);
    } else {
      // setUserGroupEdit([]);
    }
  }, [groupSetting, groupSelected]);

  useEffect(() => {
    const  isIOS = /iPad|iPhone|iPod/.test(navigator.platform) ||
                   (navigator.userAgent.includes("Mac") && "ontouchend" in document) ||
                   (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

    setIsIOs(isIOS);
  }, []);

  return (
    <div ref={slideRef} className={`${window.innerWidth < 1024 && chatIsOpen ? "relative h-screen" : "fixed z-40 lg:p-2 bottom-0 lg:bottom-2"} ${sidebarOpen ? "lg:left-[256px]" : "left-[8px]"}`}>
      {chatIsOpen && (
        <Slide container={slideRef.current} direction="up" in={open} >
          <Paper className="absolute bottom-0 block h-screen lg:h-[580px] bg-white !shadow-2xl border">
            <div className="flex items-stretch w-full h-full">
              {isLoading && (
                <div className="flex items-center justify-center w-full min-w-[350px]">
                  <CgSpinner className="w-6 h-6 mx-auto animate-spin" />
                </div>
              )}
              {!isLoading && (
                <Fragment>
                  <Fragment>
                    {window.innerWidth > 1024 && (
                      <div>
                        {ChatList()}
                      </div>
                    )}
                    {window.innerWidth < 1024 && userSelected.id == null && groupSelected.id == null && !sendDiffusion && (
                      <div>
                        {ChatList()}
                      </div>
                    )}
                    {window.innerWidth > 1024 && (
                      <div className="h-full">
                        <BoxContent
                          open={open}
                          user={userSelected}
                          group={groupSelected}
                          setGroupSelected={setGroupSelected}
                          closeHandle={() => chatStates.handle(false)}
                          messages={messages}
                          setMessages={setMessages}
                          usersDiffusion={usersDiffusion}
                          setDiffusion={setDiffusion}
                          setUsersDiffusion={setUsersDiffusion}
                          setSendDiffusion={setSendDiffusion}
                          setGroupSetting={ setGroupSetting }
                          groupSetting={ groupSetting }
                        />
                      </div>
                    )}
                    {window.innerWidth < 1024 && (userSelected.id !== null|| groupSelected.id !== null) && (
                      <div className="h-full">
                        <BoxContent
                          open={open}
                          user={userSelected}
                          group={groupSelected}
                          setGroupSelected={setGroupSelected}
                          closeHandle={() => chatStates.handle(false)}
                          messages={messages}
                          setUserSelected={setUserSelected}
                          setMessages={setMessages}
                          usersDiffusion={usersDiffusion}
                          setDiffusion={setDiffusion}
                          setUsersDiffusion={setUsersDiffusion}
                          setSendDiffusion={setSendDiffusion}
                          setGroupSetting={ setGroupSetting }
                          groupSetting={ groupSetting }
                        // soundEnabled={soundEnabled}
                        // setSoundEnabled={setSoundEnabled}
                        />
                      </div>
                    )}
                    {window.innerWidth < 1024 && sendDiffusion && (
                      <div>
                        <BoxContent
                          open={open}
                          user={userSelected}
                          group={groupSelected}
                          closeHandle={() => chatStates.handle(false)}
                          messages={messages}
                          setUserSelected={setUserSelected}
                          setMessages={setMessages}
                          setDiffusion={setDiffusion}
                          setUsersDiffusion={setUsersDiffusion}
                          setSendDiffusion={setSendDiffusion}
                          usersDiffusion={usersDiffusion}
                          setGroupSetting={ setGroupSetting }
                          groupSetting={ groupSetting }
                        // soundEnabled={soundEnabled}
                        // setSoundEnabled={setSoundEnabled}
                        />
                      </div>
                    )}
                  </Fragment>
                </Fragment>
              )}
            </div>
          </Paper>
        </Slide>
      )}
    </div>
  );
};

export default Chat;