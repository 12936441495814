import { useEffect, useState } from "react";
import ThemeMui from "@/components/Theme/Mui";
import SidebarContext from "@/components/Sidebar/Context";
import { SnackbarProvider } from "notistack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import Main from "@/components/Section/Main";

const LayoutApp = page => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [chatMinimize, setChatMinimize] = useState(true);

  const sidebar = {
    open: sidebarOpen,
    handle: setSidebarOpen
  };

  const chatStates = {
    open: chatOpen,
    handle: setChatOpen,
    minimize: chatMinimize,
    minimizeHandle: setChatMinimize
  };

  const chatClose = () => setChatOpen(!chatOpen);

  useEffect(() => {

    const resizeHandle = () => {
      if (window.innerWidth >= 1024) {
        setSidebarOpen(true);
      }
    };

    resizeHandle();
    window.addEventListener("resize", resizeHandle);
    return () => window.removeEventListener("resize", resizeHandle);
  }, []);

  return (
    <ThemeMui>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SnackbarProvider>
          <SidebarContext.Provider value={sidebar}>
            <Main
              chatStates={chatStates}
              chatClose={chatClose}
              sidebarOpen={sidebarOpen}
              page={page}
            />
          </SidebarContext.Provider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeMui>
  );
};

export default LayoutApp;