import { createRoot } from "react-dom/client";

import { createInertiaApp } from "@inertiajs/inertia-react";
import { InertiaProgress } from "@inertiajs/progress";

import LayoutApp from "@/pages/Layout/App";

// Locales
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { es } from "@/compiled/locales/es.json";
import { en } from "@/compiled/locales/en.json";

// Tailwind styles compiled
import "./../compiled/compiled.tailwind.css";

InertiaProgress.init();

i18n
  .use(initReactI18next)
  .init({
    resources: {
      es: {
        translation: es
      },
      en: {
        translation: en
      }
    },
    interpolation: {
      escapeValue: false
    }
  });

const pages = import.meta.glob("../pages/**/*.jsx");

createInertiaApp({
  resolve: name => {
    const page = pages[`../pages/${name}.jsx`]();
    page.then((module) => {
      module.default.layout = module.default.layout || LayoutApp;
    });
    return page;
  },
  setup ({ el, App, props }) {
    const root = createRoot(el);
    root.render(<App {...props} />);
  },
});