import { createTheme, ThemeProvider } from "@mui/material";

const ThemeMui = ({ children }) => {
  const theme = createTheme({
    fontFamily: [
      "Poppins",
      "sans-serif",
    ].join(","),
    typography: {
      fontFamily: [
        "Poppins",
        "sans-serif",
      ].join(","),
    },
    palette: {
      primary: {
        100: "#d1e9ff",
        200: "#a3d3ff",
        300: "#74bcff",
        400: "#46a6ff",
        500: "#1890ff",
        600: "#1373cc",
        700: "#0e5699",
        800: "#0a3a66",
        900: "#051d33"
      },
    },
    components: {
      MuiButton: {
        defaultProps: {
          disableElevation: true
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: "#ffffff",
            border: "none"
          }
        }
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& fieldset": {
              borderColor: "#E2E2E2",
            },
          },
        },
      },
      MuiPaper: {
        defaultProps: {
          elevation: 0
        }
      },
    },
  });

  // ...
  return (
    <ThemeProvider theme={theme}>
      { children }
    </ThemeProvider>
  );
};



export default ThemeMui;