import { Fragment, useEffect, useState, useRef } from "react";
import { MdArrowBack, MdClose, MdEdit, MdKeyboard, MdPerson, MdSave, MdSegment, MdSend, MdSentimentSatisfied, MdSettings } from "react-icons/md";
import BadgeOnline from "../Badge/Online";
import { useTranslation } from "react-i18next";
import { IconButton, TextField } from "@mui/material";
import { Fetch } from "@/helpers/fetch";
import { api_chat_mark_as_read_path, api_chat_messages_path, api_chat_send_message_path, api_chat_send_message_diffusion_path, api_chat_update_name_group_path } from "@/compiled/routes";
import { usePage } from "@inertiajs/inertia-react";
import EmojiPicker from "emoji-picker-react";
import ItemSms from "./ItemSms";

const BoxContent = ({
  user,
  group,
  open,
  closeHandle,
  setUserSelected,
  usersDiffusion,
  setDiffusion,
  setUsersDiffusion,
  setSendDiffusion,
  setGroupSelected,
  setGroupSetting,
  groupSetting
}) => {

  const isGroup = group && group.id > 0;

  const { t } = useTranslation();
  const { CURRENT_USER } = usePage().props;
  const [timeOut, setTimeOut] = useState(null);
  const [body, setBody] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [groupTitle, setGroupTitle] = useState("");
  const boxRef = useRef(null);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [openNameEditable, setOpenNameEditable] = useState(false);

  const [isIOs, setIsIOs] = useState(false);

  const myCallback = (code) => {
    const emoji = String.fromCodePoint(`0x${code.unified}`);
    setBody((prevValue) => prevValue + ` ${emoji}`);
  };

  function handleTextareaKeyDown (e) {
    if (e.keyCode === 13 && !e.shiftKey) {
      sendMessage(e);
    }
  }

  const handleArrowBackDiffusion = () => {
    setDiffusion(false);
    setSendDiffusion(false);
    setUsersDiffusion([]);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (body.trim() !== "") {
      if(usersDiffusion.length > 0){
        Fetch.post(api_chat_send_message_diffusion_path(), {
          body: body,
          users: JSON.stringify(usersDiffusion)
        })
          .then(() => {
            setBody("");
            setDiffusion(false);
            setUsersDiffusion([]);
            setSendDiffusion(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Fetch.post(api_chat_send_message_path(), {
          body: body,
          item_id: user.id || group.id,
          is_group: !!group.id
        })
          .then(() => {
            setBody("");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  const closeNameEditable = () => {
    setOpenNameEditable(false);
    setGroupTitle(group.name);
  };

  const updateNameGroup = (e) => {
    e.preventDefault();
    Fetch.put(api_chat_update_name_group_path(), {
      group_id: group.id,
      name: groupTitle
    })
      .then(() => {
        setOpenNameEditable(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (user.id || group.id) {
      setIsLoading(true);
      Fetch.get(api_chat_messages_path({
        item_id: user.id || group.id,
        is_group: !!group.id
      }))
        .then(({ data }) => {
          setIsLoading(false);
          setMessages(data.messages);
        });
    }
  }, [user.id, group.id]);

  useEffect(() => {
    const handle = ({ detail: cable_data }) => {
      const { data } = cable_data;

      const temp = [...messages];
      temp.push(data.message);
      setMessages(temp);
    };

    document.addEventListener("QUICK_CHAT_SENT_CABLE", handle);
    return () => document.removeEventListener("QUICK_CHAT_SENT_CABLE", handle);
  }, [messages]);

  useEffect(() => {
    const handle = ({ detail: cable_data }) => {
      const { data } = cable_data;
      if(data.is_group){
        if(group && data.group_id == group.id){
          const temp = [...messages];
          temp.push(data.message);
          setMessages(temp);
        }
      } else {
        if (data.user_id == user.id) {
          const temp = [...messages];
          temp.push(data.message);
          setMessages(temp);
        }
      }
    };

    document.addEventListener("QUICK_CHAT_RECEIVER_CABLE", handle);
    return () => document.removeEventListener("QUICK_CHAT_RECEIVER_CABLE", handle);
  }, [messages]);

  useEffect(() => {
    if (boxRef.current && open) {
      boxRef.current.scrollTop = boxRef.current.scrollHeight;
      if (timeOut) {
        clearTimeout(timeOut);
        setTimeOut(null);
      }
      const time = setTimeout(() => {
        Fetch.put(api_chat_mark_as_read_path(), {
          item_id: user.id || group.id,
          is_group: isGroup
        })
          .then(() => {
          });
      }, 2000);
      setTimeOut(time);
    }
  }, [messages, open]);

  useEffect(() => {
    if(group.name){
      setGroupTitle(group.name);
    }
  }, [group]);

  useEffect(() => {
    const  isIOS = /iPad|iPhone|iPod/.test(navigator.platform) ||
                   (navigator.userAgent.includes("Mac") && "ontouchend" in document) ||
                   (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

    setIsIOs(isIOS);
  }, []);

  return (
    <div
      id="quick-chat-box-cable"
      className={`relative flex flex-col w-screen h-full lg:w-[420px] ${isIOs && "pt-[110px]"}`}
    >
      {((user && user.id) || (group, group.id)) && usersDiffusion.length == 0 && (
        <Fragment>
          <header className={
            `flex items-center justify-between flex-none w-full h-16 py-1 pl-2 space-x-4 text-base font-medium text-gray-800 truncate bg-white border-b ${isGroup ? "pr-2" : "pr-5"}`
          }>
            <div className="flex items-center truncate group">
              <IconButton onClick={() => {setUserSelected({ id: null }); setGroupSelected({ id: null });}} className="lg:!hidden">
                <MdArrowBack className="w-5 h-5" />
              </IconButton>

              { !openNameEditable && (
                <span>{user.display_name || groupTitle}</span>
              ) }

              { openNameEditable && (
                <div>
                  <TextField
                    size="small"
                    value={groupTitle}
                    variant="standard"
                    onChange={e => setGroupTitle(e.target.value)}
                  />
                  <IconButton
                    onClick={ updateNameGroup }
                  >
                    <MdSave className="w-4 h-4" />
                  </IconButton>
                  <IconButton
                    onClick={ closeNameEditable }
                  >
                    <MdClose className="w-4 h-4" />
                  </IconButton>
                </div>
              ) }
              { !openNameEditable && isGroup && (
                <div className="hidden ml-2 group-hover:block">
                  <IconButton
                    onClick={() => setOpenNameEditable(!openNameEditable)}
                  >
                    <MdEdit className="w-4 h-4" />
                  </IconButton>
                </div>
              ) }
            </div>
            <div>
              { isGroup && window.innerWidth > 768 && (
                <IconButton
                  onClick={ () => setGroupSetting(!groupSetting) }
                >
                  { groupSetting && (
                    <MdSegment className="w-5 h-5" />
                  ) }
                  { !groupSetting && (
                    <MdSettings className="w-5 h-5" />
                  ) }
                </IconButton>
              ) }
              { !isGroup && (
                <BadgeOnline
                  overlap="circular"
                  className="!block"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                  active={user && user.online ? "true" : undefined}
                />
              ) }
            </div>
          </header>
          <div className="flex flex-col relative h-[calc(100%-64px)]">
            <img
                className="absolute inset-0 z-20 object-cover w-full h-screen opacity-30"
                src="/images/bgsms.jpg"
                alt=""
              />
            <div
              ref={boxRef}
              className="h-[calc(100%-70px)] absolute inset-0 z-20 object-cover p-2 overflow-auto space-y-2"
            >
              {/* <div className=""> */}
                {messages.map((message, index) => (
                  <ItemSms
                    isGroup={isGroup}
                    message={message}
                    CURRENT_USER={CURRENT_USER}
                    key={index}
                  />
                ))}
              {/* </div> */}
            </div>
            <div className="absolute inset-x-0 bottom-0 z-30 flex flex-col py-3 bg-white border-t">
              <div className="flex flex-row">
              <IconButton onClick={() => setOpenEmoji(!openEmoji)}>
                {openEmoji ? <MdKeyboard className="w-6 h-6" /> : <MdSentimentSatisfied className="w-6 h-6" /> }
              </IconButton>
              <TextField
                multiline
                size="small"
                className="!w-full"
                InputProps={{
                  className: "!text-sm !text-gray-600"
                }}
                variant="standard"
                placeholder="Message"
                rows={2}
                maxRows={4}
                onKeyDown={handleTextareaKeyDown}
                value={body}
                onChange={e => setBody(e.target.value)}
              />
              <div className="flex justify-end">
                <IconButton onClick={sendMessage} color="primary">
                  <MdSend className="flex-none w-4 h-4" />
                </IconButton>
              </div>
              </div>
              {openEmoji && (
              <div className="flex justify-center">
                <EmojiPicker
                  searchDisabled={true}
                  skinTonesDisabled={true}
                  onEmojiClick={myCallback}
                  className="!rounded-none"
                />
              </div>
            )}
            </div>
          </div>
        </Fragment>
      )}
      {!isGroup && user && !user.id && usersDiffusion.length == 0 && (
        <div className="flex flex-col items-center justify-center h-full">
          <MdPerson className="flex-none w-12 h-12 text-gray-500" />
          <div className="text-sm italic">{t("user_not_selected")}</div>
        </div>
      )}
      {user && !user.id && usersDiffusion.length > 0 && (
        <div className="flex flex-col h-full">
          <div className="flex justify-end px-2 bg-gray-300 borber-b lg:hidden">
            <IconButton
              variant="contained"
              onClick={handleArrowBackDiffusion}
            >
              <MdArrowBack className="w-8 h-7"/>
            </IconButton>
          </div>
          <div className="h-[calc(100%-64px)]"></div>
          <div className="relative flex flex-row py-3 border-t">
            <IconButton onClick={() => setOpenEmoji(!openEmoji)}>
              {openEmoji ? <MdKeyboard className="w-6 h-6" /> : <MdSentimentSatisfied className="w-6 h-6" /> }
            </IconButton>
            <TextField
              multiline
              size="small"
              className="!w-full"
              InputProps={{
                className: "!text-sm !text-gray-600"
              }}
              variant="standard"
              placeholder="Message"
              rows={2}
              maxRows={4}
              onKeyDown={handleTextareaKeyDown}
              value={body}
              onChange={e => setBody(e.target.value)}
            />
            <div className="flex justify-end">
              <IconButton onClick={sendMessage} color="primary">
                <MdSend className="flex-none w-4 h-4" />
              </IconButton>
            </div>
          </div>
        </div>
      )}
      <div className="absolute top-[-40px] right-[-40px] z-20">
        <IconButton
          variant="contained"
          color="primary"
          onClick={closeHandle}
          className="!bg-primary-500"
        >
          <MdClose className="flex-none w-6 h-6 text-gray-100" />
        </IconButton>
      </div>
    </div>
  );
};

export default BoxContent;